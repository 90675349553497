import React from 'react';
import { Link } from 'react-router-dom';
import { useSetting } from '../store/SettingContext';
import parse from 'html-react-parser';

export default function Footer() {
    const setting = useSetting();
    return (
        <>
            <footer className="bg-slate-800 dark:bg-slate-950">
                <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-10 md:pb-7 md:px-6 lg:space-y-16 lg:px-8">
                    <div className="flex flex-col md:flex-row gap-y-3 justify-between">
                        <div className="w-full md:1/2">
                            {setting.wideLogo && (
                                <Link to="/" className="logo-footer inline-flex">
                                    <img src={setting.wideLogo} className="md:ms-0 mx-auto max-h-20 md:max-h-24" alt="Logo" />
                                </Link>
                            )}
                            <div className="mt-4 space-y-1 md:max-w-xl">
                                {setting.heroDescription && <p className="text-gray-300 text-left">{setting.heroDescription}</p>}
                                {setting.address && <p className="text-gray-300 text-left">{parse(setting.address)}</p>}
                                {setting.phone && <p className="text-gray-300 text-left">{setting.phone}</p>}
                                {setting.email && <p className="text-gray-300 text-left">{setting.email}</p>}
                            </div>
                            <div className="flex justify-center flex-col md:flex-row md:justify-start my-10 gap-2.5">
                                {setting.playStore && (
                                    <a href={setting.playStore} target="_blank" rel="noopener noreferrer">
                                        <div className="flex items-center border border-slate-700/50 dark:border-slate-800 rounded-lg p-5 md:pl-3 md:pr-5 md:py-3 w-auto">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="w-10 h-10 text-gray-300 dark:text-gray-200">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 3.71v16.58a.7 .7 0 0 0 1.05 .606l14.622 -8.42a.55 .55 0 0 0 0 -.953l-14.622 -8.419a.7 .7 0 0 0 -1.05 .607z" />
                                                <path d="M15 9l-10.5 11.5" />
                                                <path d="M4.5 3.5l10.5 11.5" />
                                            </svg>
                                            <div className="text-left ml-2.5">
                                                <p className="text-xs text-slate-400 mb-1">Unduh di </p>
                                                <p className="text-base text-slate-200">Google Play Store</p>
                                            </div>
                                        </div>
                                    </a>
                                )}
                                {setting.appleStore && (
                                    <a href={setting.appleStore} target="_blank" rel="noopener noreferrer">
                                        <div className="flex items-center border border-slate-700/50 dark:border-slate-800 rounded-lg p-5 md:pl-3 md:pr-5 md:py-3 w-auto">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 text-gray-300 dark:text-gray-200">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M15.079 5.999l.239 .012c1.43 .097 3.434 1.013 4.508 2.586a1 1 0 0 1 -.344 1.44c-.05 .028 -.372 .158 -.497 .217a4.15 4.15 0 0 0 -.722 .431c-.614 .461 -.948 1.009 -.942 1.694c.01 .885 .339 1.454 .907 1.846c.208 .143 .436 .253 .666 .33c.126 .043 .426 .116 .444 .122a1 1 0 0 1 .662 .942c0 2.621 -3.04 6.381 -5.286 6.381c-.79 0 -1.272 -.091 -1.983 -.315l-.098 -.031c-.463 -.146 -.702 -.192 -1.133 -.192c-.52 0 -.863 .06 -1.518 .237l-.197 .053c-.575 .153 -.964 .226 -1.5 .248c-2.749 0 -5.285 -5.093 -5.285 -9.072c0 -3.87 1.786 -6.92 5.286 -6.92c.297 0 .598 .045 .909 .128c.403 .107 .774 .26 1.296 .508c.787 .374 .948 .44 1.009 .44h.016c.03 -.003 .128 -.047 1.056 -.457c1.061 -.467 1.864 -.685 2.746 -.616l-.24 -.012z" />
                                                <path d="M14 1a1 1 0 0 1 1 1a3 3 0 0 1 -3 3a1 1 0 0 1 -1 -1a3 3 0 0 1 3 -3z" />
                                            </svg>

                                            <div className="text-left ml-2.5">
                                                <p className="text-xs text-slate-400 mb-1">Unduh di </p>
                                                <p className="text-base text-slate-200">Apple Store</p>
                                            </div>
                                        </div>
                                    </a>
                                )}
                            </div>
                        </div>
                        {setting.embedGmap && (
                            <div className="w-full md:w-1/2">
                                <div className="overflow-hidden rounded-xl [&>iframe]:!aspect-square [&>iframe]:!w-full [&>iframe]:!h-full [&>iframe]:border-none [&>iframe]:invert-0 [&>iframe]:dark:invert">
                                    <iframe src={setting.embedGmap} title="maps-location" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col-reverse md:flex-row md:justify-between md:items-center gap-y-5 border-t border-slate-700/50 dark:border-slate-800 pt-8">
                        <p className="text-base text-slate-400 dark:text-slate-300 md:text-left text-center">
                            &copy; 2023 - {new Date().getFullYear()} {setting.copyrightText}
                        </p>
                        <ul className="flex gap-x-4 justify-center md:justify-end">
                            {setting.youtube && (
                                <li>
                                    <a href={setting.youtube} rel="noreferrer" target="_blank" className="text-slate-400 dark:text-slate-300 transition hover:opacity-75">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" />
                                            <path d="M10 9l5 3l-5 3z" />
                                        </svg>
                                        <span className="sr-only">Youtube</span>
                                    </a>
                                </li>
                            )}

                            {setting.facebook && (
                                <li>
                                    <a href={setting.facebook} rel="noreferrer" target="_blank" className="text-slate-400 dark:text-slate-300 transition hover:opacity-75">
                                        <span className="sr-only">Facebook</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                        </svg>
                                    </a>
                                </li>
                            )}

                            {setting.instagram && (
                                <li>
                                    <a href={setting.instagram} rel="noreferrer" target="_blank" className="text-slate-400 dark:text-slate-300 transition hover:opacity-75">
                                        <span className="sr-only">Instagram</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                                            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                            <path d="M16.5 7.5l0 .01" />
                                        </svg>
                                    </a>
                                </li>
                            )}

                            {setting.twitter && (
                                <li>
                                    <a href={setting.twitter} rel="noreferrer" target="_blank" className="text-slate-400 dark:text-slate-300 transition hover:opacity-75">
                                        <span className="sr-only">Twitter</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                                            <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
                                        </svg>
                                    </a>
                                </li>
                            )}

                            {setting.tiktok && (
                                <li>
                                    <a href={setting.tiktok} rel="noreferrer" target="_blank" className="text-slate-400 dark:text-slate-300 transition hover:opacity-75">
                                        <span className="sr-only">Tiktok</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M21 7.917v4.034a9.948 9.948 0 0 1 -5 -1.951v4.5a6.5 6.5 0 1 1 -8 -6.326v4.326a2.5 2.5 0 1 0 4 2v-11.5h4.083a6.005 6.005 0 0 0 4.917 4.917z" />
                                        </svg>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </footer>
            {/* <!-- Back to top --> */}
            <Link to="home" id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-emerald-600 text-white leading-9">
                <i className="uil uil-arrow-up"></i>
            </Link>
            {/* <!-- Back to top --> */}
        </>
    );
}
