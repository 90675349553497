import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../component/Loader.js";

const BookingIndex = React.lazy(() => import("../../pages/booking/index.js"));
const NotFound = React.lazy(() => import("../../pages/NotFound.js"));

const BookingRoutes = () => {
	return (
		<Routes>
			<Route
				index
				element={
					<Suspense fallback={<Loader />}>
						<BookingIndex />
					</Suspense>
				}
			/>
			<Route
				path="*"
				element={
					<Suspense fallback={<Loader />}>
						<NotFound />
					</Suspense>
				}
			/>
		</Routes>
	);
};

export default BookingRoutes;
