import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SettingProvider } from './store/SettingContext';
import { AuthProvider } from './store/AuthContext';
import { SWRConfig, mutate } from 'swr';
import ScrollToTop from './component/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SettingProvider>
        <AuthProvider>
            <BrowserRouter>
                <ScrollToTop />
                <SWRConfig
                    value={{
                        onErrorRetry: (error, key, revalidate, { retryCount }) => {
                            // Never retry on 404.
                            if (error.response?.status === 404) return;

                            // Only retry up to 10 times.
                            if (retryCount >= 10) return;

                            // Retry after 5 seconds.
                            setTimeout(() => mutate({ retryCount }), 10000);
                        },
                    }}
                >
                    <App />
                </SWRConfig>
            </BrowserRouter>
        </AuthProvider>
    </SettingProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
