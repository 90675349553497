import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth, useAuthDispatch } from "../store/AuthContext";

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
axios.defaults.withCredentials = false;

export const useHttp = (params) => {
	const cancelTokenSource = useRef(axios.CancelToken.source());
	let navigate = useNavigate();
	const auth = useAuth();
	const dispatch = useAuthDispatch();
	if (auth.isLoggedIn) {
		axios.defaults.headers.common["Authorization"] = "Bearer " + auth.token;
	}
	axios.defaults.headers.common["Accept"] = "application/json";

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const sendRequest = useCallback(
		async (
			params,
			applyResponse,
			loadingAfterApply = true,
			rejectError = false
		) => {
			setLoading(true);
			try {
				const result = await axios.request(
					Object.assign(params, {
						cancelToken: cancelTokenSource.current.token,
					})
				);
				setError(null);
				applyResponse(result.data);
				if (loadingAfterApply) {
					setLoading(false);
				}
			} catch (error) {
				if (error.message !== "request aborted") {
					setError(error.response);
					setLoading(false);
					if (rejectError) {
						return Promise.reject(error);
					}
				}
			}
		},
		[cancelTokenSource]
	);

	const toastErrors = useCallback((error) => {
		var errors = error.data.errors ? error.data.errors : error.data;
		for (var key in errors) {
			var validation_error = errors[key];
			for (var message in validation_error) {
				toast.error(validation_error[message]);
			}
		}
	}, []);

	useEffect(() => {
		if (error) {
			switch (error.status) {
				case 422:
					toastErrors(error);
					break;

				case 401:
					if (error.data.message === "Unauthenticated.") {
						dispatch({
							type: "logout",
						});
						toast.error("Mohon Login Untuk Melanjutkan !");
					} else {
						toastErrors(error);
					}
					break;

				case 403:
					toast.error("Akses anda ditolak !");
					break;

				case 404:
					navigate("/not-found");
					break;

				case 405:
					toast.error("Aksi tidak dikenali !");
					break;

				default:
					console.log(error);
					break;
			}
		}
	}, [error, toastErrors, navigate, dispatch]);

	useEffect(() => {
		const cancelSignal = cancelTokenSource.current;
		return () => {
			cancelSignal.cancel("request aborted");
		};
	}, [cancelTokenSource]);

	return { error, loading, sendRequest, setLoading };
};

export default useHttp;
