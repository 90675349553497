import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../../component/Loader.js';
import HomeLayout from '../../component/home-layout.js';
import { useAuth } from '../../store/AuthContext.js';

import AgencyRoutes from './agency.js';
import ArticleRoutes from './article.js';
import BookingRoutes from './booking.js';
import ChatRoutes from './chat.js';
import ComplaintRoutes from './complaint.js';
import ServiceRoutes from './service.js';

const ContactUs = React.lazy(() => import('../../pages/ContactUs.js'));
const Index = React.lazy(() => import('../../pages/index.js'));
const PageShow = React.lazy(() => import('../../pages/page/Show.js'));
const Profile = React.lazy(() => import('../../pages/auth/Profile.js'));
const ChangePassword = React.lazy(() => import('../../pages/auth/ChangePassword.js'));
const NotFound = React.lazy(() => import('../../pages/NotFound.js'));

const HomeRoute = () => {
    const auth = useAuth();

    return (
        <HomeLayout>
            <Routes>
                <Route
                    index
                    element={
                        <Suspense fallback={<Loader />}>
                            <Index />
                        </Suspense>
                    }
                />
                <Route
                    path="/contact-us"
                    element={
                        <Suspense fallback={<Loader />}>
                            <ContactUs />
                        </Suspense>
                    }
                />
                <Route path="/article/*" element={<ArticleRoutes />} />

                <Route
                    path="/page/:pageSlug"
                    element={
                        <Suspense fallback={<Loader />}>
                            <PageShow />
                        </Suspense>
                    }
                />
                <Route
                    path="/booking/*"
                    element={
                        auth.isLoggedIn ? (
                            <Suspense fallback={<Loader />}>
                                <BookingRoutes />
                            </Suspense>
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route
                    path="/chat/*"
                    element={
                        auth.isLoggedIn ? (
                            <Suspense fallback={<Loader />}>
                                <ChatRoutes />
                            </Suspense>
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route
                    path="/complaint/*"
                    element={
                        auth.isLoggedIn ? (
                            <Suspense fallback={<Loader />}>
                                <ComplaintRoutes />
                            </Suspense>
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route
                    path="/profile"
                    element={
                        auth.isLoggedIn ? (
                            <Suspense fallback={<Loader />}>
                                <Profile />
                            </Suspense>
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route
                    path="/change-password"
                    element={
                        auth.isLoggedIn ? (
                            <Suspense fallback={<Loader />}>
                                <ChangePassword />
                            </Suspense>
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route path="/service/*" element={<ServiceRoutes />} />
                <Route path="/agency/*" element={<AgencyRoutes />} />
                <Route
                    path="*"
                    element={
                        <Suspense fallback={<Loader />}>
                            <NotFound />
                        </Suspense>
                    }
                />
            </Routes>
        </HomeLayout>
    );
};

export default HomeRoute;
