const InnerPageWrapper = ({ children, title }) => {
	return (
		<section className="pt-28 w-full table relative mb-20">
			<div className="absolute inset-0 -z-1 bg-gradient-to-b from-emerald-600/20 dark:from-emerald-600/40 via-emerald-600/10 dark:via-emerald-600/20 to-transparent" />
			<div className="container">
				{title ? (
					<div className="grid grid-cols-1 pb-8 text-center mt-10">
						<h3 className="mb-3 font-medium leading-normal text-3xl mt-10 text-black dark:text-white">
							{title}
						</h3>
					</div>
				) : (
					""
				)}

				{children}
			</div>
		</section>
	);
};

export default InnerPageWrapper;
