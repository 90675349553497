import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from '../../component/Loader.js';

const ComplaintIndex = React.lazy(() => import('../../pages/complaint/index.js'));
const Create = React.lazy(() => import('../../pages/complaint/Create.js'));
const Show = React.lazy(() => import('../../pages/complaint/Show.js'));
const NotFound = React.lazy(() => import('../../pages/NotFound.js'));

const ComplaintRoutes = () => {
    return (
        <Routes>
            <Route
                index
                element={
                    <Suspense fallback={<Loader />}>
                        <ComplaintIndex />
                    </Suspense>
                }
            />
            <Route
                path="/:complaintId"
                element={
                    <Suspense fallback={<Loader />}>
                        <Show />
                    </Suspense>
                }
            />
            <Route
                path="/create"
                element={
                    <Suspense fallback={<Loader />}>
                        <Create />
                    </Suspense>
                }
            />
            <Route
                path="*"
                element={
                    <Suspense fallback={<Loader />}>
                        <NotFound />
                    </Suspense>
                }
            />
        </Routes>
    );
};

export default ComplaintRoutes;
