import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { BarLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import BackgroudImage from '../../assets/images/bg/auth.jpg';
import Switcher from '../../component/Switcher';
import useHttp from '../../hooks/use-http';
import { useSetting } from '../../store/SettingContext';

const Forgot = () => {
    const setting = useSetting();
    const { loading, sendRequest: loginRequest } = useHttp();
    const usernameInputRef = useRef();
    const nameInputRef = useRef();
    const navigate = useNavigate();

    const loginHandler = (event) => {
        event.preventDefault();

        const enteredUsername = usernameInputRef.current.value;
        const enteredName = nameInputRef.current.value;
        loginRequest(
            {
                method: 'POST',
                url: '/forgot-password',
                data: {
                    nik: enteredUsername,
                    name: enteredName,
                },
            },
            (response) => {
                toast.success(response.message);
                navigate('/login');
            },
            false
        );
    };

    return (
        <>
            <section
                style={{
                    backgroundImage: `url(${setting.publicAuthBackground || BackgroudImage})`,
                }}
                className="position-relative bg-center bg-cover"
            >
                <div className="absolute inset-0 bg-black opacity-90"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                                {setting.wideLogo && (
                                    <div className="text-center mx-auto">
                                        <Link to="/">
                                            <img className="max-h-20" src={setting.wideLogo} alt="app-logo" />
                                        </Link>
                                    </div>
                                )}
                                <div className=" my-auto">
                                    <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-700">
                                        <div className="grid grid-cols-1">
                                            <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Reset Password</h5>
                                            <form className="ltr:text-left rtl:text-right" onSubmit={loginHandler}>
                                                <div className="grid grid-cols-1">
                                                    <div className="mb-4">
                                                        <label className="dark:text-white" htmlFor="name">
                                                            Nama Lengkap
                                                        </label>
                                                        <input id="name" type="text" className="form-input mt-2" placeholder="Masukan nama lengkap anda" ref={nameInputRef} />
                                                    </div>

                                                    <div className="mb-4">
                                                        <label className="dark:text-white" htmlFor="nik">
                                                            NIK
                                                        </label>
                                                        <input id="nik" type="number" className="form-input mt-2" placeholder="Masukan nik anda" ref={usernameInputRef} />
                                                    </div>

                                                    <div className="mb-4 space-y-2 mt-2">
                                                        <div className="form-group mb-0 text-center flex">
                                                            {!loading && <button className="btn bg-emerald-600 text-sm font-medium hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-lg w-full py-2.5">Reset password</button>}
                                                            <BarLoader color="#047857" loading={loading} height={4} width="100%" />
                                                        </div>
                                                        <Link to="/login" className="text-gray-700 flex justify-center items-center w-full pt-5 gap-x-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 text-gray-600">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M5 12l14 0" />
                                                                <path d="M5 12l6 6" />
                                                                <path d="M5 12l6 -6" />
                                                            </svg>

                                                            <span className="block">Back to login</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Start Footer --> */}
                                <div className="text-center">
                                    <p className="text-gray-400">
                                        © 2023 - {new Date().getFullYear()} {setting.appName}. All rights reserved.
                                    </p>
                                </div>
                                {/* <!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Switcher section */}
            <Switcher />
        </>
    );
};

export default Forgot;
