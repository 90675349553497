import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import moment from 'moment';
import 'moment/locale/id';

import { useAuth } from '../../store/AuthContext';
import { generalFetch } from '../../api/generalFetcher';
import InnerPageWrapper from '../../component/InnerPageWrapper';
import NewChatModal from '../../component/chat/NewChatModal';
import { ellipsis } from '../../utility/utility';

const ChatIndex = () => {
    const auth = useAuth();
    const [open, setOpen] = useState(false);
    const {
        data: chatsResponse,
        error,
        isLoading,
    } = useSWR('/chat', (url) => generalFetch(url, auth.token), {
        refreshInterval: 30000,
    });

    const ChatList = () => {
        return chatsResponse.agencies.length === 0 ? (
            <div className="h-24 flex items-center justify-center">
                <h4 className="text-sm font-semibold text-neutral-800 dark:text-neutral-50 text-center">Tidak ada data live chat !</h4>
            </div>
        ) : (
            chatsResponse.agencies.map((agency) => (
                <Link key={agency.id} to={`/chat/${agency.id}`} className="w-full text-left py-2 focus:outline-none focus-visible:bg-indigo-50 ">
                    <div className="flex justify-between items-center p-2 hover:bg-indigo-100 dark:hover:bg-slate-700">
                        <div className="flex">
                            <img className="rounded-full items-start flex-shrink-0 mr-3 object-contain" src={agency.thumbnail_url || '/logo192.png'} width="48" height="48" alt="logo instansi" />
                            <div>
                                <h4 className="text-sm font-semibold text-neutral-800 dark:text-neutral-50">{agency.name}</h4>
                                <div className="text-[13px] text-neutral-600 dark:text-neutral-200">{ellipsis(agency.last_chat.message, 10)}</div>
                                <div className="text-[13px] text-neutral-600 dark:text-neutral-200">{moment(agency.last_chat.created_at).locale('id').format('LLLL')}</div>
                            </div>
                        </div>
                        {agency.unread_chat !== 0 && <span className="rounded-md bg-emerald-50 dark:bg-slate-500 px-2 py-1 text-xs font-medium text-emerald-700 dark:text-neutral-50 ring-1 ring-inset ring-green-600/20">{agency.unread_chat}</span>}
                    </div>
                </Link>
            ))
        );
    };

    return (
        <InnerPageWrapper title="Live Chat">
            <section className="flex flex-col antialiased text-gray-600 p-4">
                <div className="h-full">
                    <div className="relative mx-auto bg-white dark:bg-slate-900 shadow-lg rounded-lg">
                        <div className="py-3 px-5">
                            <div className="flex justify-between py-3 pr-3">
                                <h3 className="text-xs font-semibold uppercase text-neutral-800 dark:text-neutral-50 mb-1">LIVE CHAT INSTANSI</h3>
                                <button className="items-center text-sm font-medium text-white bg-emerald-800 hover:bg-emerald-700 active:bg-emerald-900 rounded-md text-center px-3 py-2 shadow-lg focus:outline-none focus-visible:ring-2" onClick={() => setOpen(true)}>
                                    <span>+</span>
                                </button>
                            </div>
                            <div className="divide-y divide-gray-200">
                                {(isLoading || error) && <Skeleton count={5} />}
                                {!isLoading && !error && <ChatList />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NewChatModal open={open} setOpen={setOpen} />
        </InnerPageWrapper>
    );
};

export default ChatIndex;
