import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../component/Loader.js";

const ArticleIndex = React.lazy(() => import("../../pages/article/index.js"));
const Show = React.lazy(() => import("../../pages/article/Show.js"));
const NotFound = React.lazy(() => import("../../pages/NotFound.js"));

const ArticleRoutes = () => {
	return (
		<Routes>
			<Route
				index
				element={
					<Suspense fallback={<Loader />}>
						<ArticleIndex />
					</Suspense>
				}
			/>
			<Route
				path="/:articleId/*"
				element={
					<Suspense fallback={<Loader />}>
						<Show />
					</Suspense>
				}
			/>
			<Route
				path="*"
				element={
					<Suspense fallback={<Loader />}>
						<NotFound />
					</Suspense>
				}
			/>
		</Routes>
	);
};

export default ArticleRoutes;
