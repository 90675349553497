import axios from "axios";

export const settingFetch = (url) =>
	axios
		.get(`${process.env.REACT_APP_BASE_API_URL}${url}`)
		.then((res) => res.data);

export const serviceFetch = (url) =>
	axios
		.get(`${process.env.REACT_APP_BASE_API_URL}${url}`)
		.then((res) => res.data);

export const articleFetch = async (url) =>
	await axios
		.get(`${process.env.REACT_APP_BASE_API_URL}${url}`)
		.then((res) => res.data);

export const generalFetch = async (url, token) =>
	await axios
		.get(`${process.env.REACT_APP_BASE_API_URL}${url}`, {
			headers: token && { Authorization: "Bearer " + token },
		})
		.then((res) => res.data);
