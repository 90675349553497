import { useContext } from 'react';
import { useReducer } from 'react';
import { createContext } from 'react';

const SettingContext = createContext(null);
const SettingDispatchContext = createContext(null);

const initialState = {
    wideLogo: null,
    rectangleLogoUrl: null,
    publicAuthBackground: null,
    appName: null,
    heroDescription: null,
    heroVideo: null,
    heroImage1: null,
    heroImage2: null,
    copyrightText: null,
    aboutDescription: null,
    aboutVideo: null,
    aboutImage: null,
    playStore: null,
    appleStore: null,
    youtube: null,
    facebook: null,
    instagram: null,
    twitter: null,
    tiktok: null,
};

const settingReducer = (setting, action) => {
    switch (action.type) {
        case 'update': {
            return {
                appName: action.setting.app_name,
                wideLogo: action.setting.wide_logo_url,
                rectangleLogo: action.setting.rectangle_logo_url,
                phone: action.setting.phone,
                email: action.setting.email,
                embedGmap: action.setting.embed_gmap,
                publicAuthBackground: action.setting.public_auth_background_url,
                videoScreen: action.setting.video_screen_url,
                heroDescription: action.setting.hero_description,
                heroVideo: action.setting.hero_video,
                heroImage1: action.setting.hero_image_1_url,
                heroImage2: action.setting.hero_image_2_url,
                aboutDescription: action.setting.about_description,
                aboutVideo: action.setting.about_video,
                aboutImage: action.setting.about_image_url,
                address: action.setting.address,
                copyrightText: action.setting.copyright_text,
                playStore: action.setting.play_store,
                appleStore: action.setting.apple_store,
                youtube: action.setting.youtube,
                facebook: action.setting.facebook,
                instagram: action.setting.instagram,
                twitter: action.setting.twitter,
                tiktok: action.setting.tiktok,
            };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
};

export const SettingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(settingReducer, initialState);

    return (
        <SettingContext.Provider value={state}>
            <SettingDispatchContext.Provider value={dispatch}>{children}</SettingDispatchContext.Provider>
        </SettingContext.Provider>
    );
};

export function useSetting() {
    return useContext(SettingContext);
}

export function useSettingDispatch() {
    return useContext(SettingDispatchContext);
}
