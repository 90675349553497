import { useContext } from "react";
import { useReducer } from "react";
import { createContext } from "react";

const AuthContext = createContext(null);
const AuthDispatchContext = createContext(null);

const initialState = {
	user: JSON.parse(localStorage.getItem("user")) || {},
	token: localStorage.getItem("token") || "",
	fcmToken: localStorage.getItem("fcmToken") || "",
	isLoggedIn: !!localStorage.getItem("token"),
};

const authReducer = (auth, action) => {
	switch (action.type) {
		case "login": {
			localStorage.setItem("user", JSON.stringify(action.user));
			localStorage.setItem("token", action.token);

			return {
				user: action.user,
				token: action.token,
				isLoggedIn: true,
			};
		}

		case "updateProfile": {
			localStorage.setItem(
				"user",
				JSON.stringify({
					...action.user,
				})
			);

			return {
				...auth,
				user: action.user,
			};
		}

		case "updateProfilePicture": {
			localStorage.setItem(
				"user",
				JSON.stringify({
					...auth.user,
					profile_picture: action.profile_picture,
					profile_picture_url: action.profile_picture_url,
				})
			);

			return {
				...auth,
				user: {
					...auth.user,
					profile_picture: action.profile_picture,
					profile_picture_url: action.profile_picture_url,
				},
			};
		}

		case "updateIdCardPhoto": {
			localStorage.setItem(
				"user",
				JSON.stringify({
					...auth.user,
					citizen: {
						...auth.user.citizen,
						id_card_photo: action.id_card_photo,
						id_card_photo_url: action.id_card_photo_url,
					},
				})
			);

			return {
				...auth,
				user: {
					...auth.user,
					citizen: {
						...auth.user.citizen,
						id_card_photo: action.id_card_photo,
						id_card_photo_url: action.id_card_photo_url,
					},
				},
			};
		}

		case "logout": {
			navigator.sendBeacon(
				`${process.env.REACT_APP_BASE_API_URL}/unsubscribe-topic?topic=user-chat-${auth.user.id}&registration_token=${auth.fcmToken}`
			);
			localStorage.removeItem("token");
			localStorage.removeItem("user");

			return {
				user: {},
				token: "",
				isLoggedIn: false,
			};
		}

		case "storeFcmToken": {
			localStorage.setItem("fcmToken", action.fcmToken);

			return {
				...auth,
				fcmToken: action.fcmToken,
			};
		}

		default: {
			throw Error("Unknown action: " + action.type);
		}
	}
};

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, initialState);

	return (
		<AuthContext.Provider value={state}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthContext.Provider>
	);
};

export function useAuth() {
	return useContext(AuthContext);
}

export function useAuthDispatch() {
	return useContext(AuthDispatchContext);
}
