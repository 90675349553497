import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../component/Loader.js';
import { useAuth } from '../store/AuthContext.js';

import HomeRoute from './home/index.js';
import Forgot from '../pages/auth/Forgot.js';

const AgencyPrintScreen = React.lazy(() => import('../pages/print/AgencyScreen.js'));
const PrintScreen = React.lazy(() => import('../pages/print/Screen.js'));
const QueueScreen = React.lazy(() => import('../pages/queue/Screen.js'));
const BookingCodeShow = React.lazy(() => import('../pages/booking/BookingCodeShow.js'));
const SignIn = React.lazy(() => import('../pages/auth/SignIn.js'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp.js'));

const AllRoutes = () => {
    const auth = useAuth();

    return (
        <Routes>
            <Route
                path="/login"
                element={
                    !auth.isLoggedIn ? (
                        <Suspense fallback={<Loader />}>
                            <SignIn />
                        </Suspense>
                    ) : (
                        <Navigate to="/" />
                    )
                }
            />
            <Route
                path="/register"
                element={
                    !auth.isLoggedIn ? (
                        <Suspense fallback={<Loader />}>
                            <SignUp />
                        </Suspense>
                    ) : (
                        <Navigate to="/" />
                    )
                }
            />
            <Route
                path="/forgot-password"
                element={
                    !auth.isLoggedIn ? (
                        <Suspense fallback={<Loader />}>
                            <Forgot />
                        </Suspense>
                    ) : (
                        <Navigate to="/" />
                    )
                }
            />
            <Route
                path="/queue"
                element={
                    <Suspense fallback={<Loader />}>
                        <QueueScreen />
                    </Suspense>
                }
            />
            <Route
                path="/print"
                element={
                    <Suspense fallback={<Loader />}>
                        <PrintScreen />
                    </Suspense>
                }
            />
            <Route
                path="/print/:agencyId"
                element={
                    <Suspense fallback={<Loader />}>
                        <AgencyPrintScreen />
                    </Suspense>
                }
            />
            <Route
                path="/booking-code"
                element={
                    <Suspense fallback={<Loader />}>
                        <BookingCodeShow />
                    </Suspense>
                }
            />
            <Route path="*" element={<HomeRoute />} />
        </Routes>
    );
};

export default AllRoutes;
