import Footer from '../component/Footer';
import Switcher from '../component/Switcher';
import Navbar from './Navbar';

/**
 * Index eight
 */
export default function HomeLayout({ children }) {
    window.addEventListener('scroll', windowScroll);

    /**
     * Window scroll
     */
    function windowScroll() {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
                navbar.classList.add('is-sticky');
            } else {
                navbar.classList.remove('is-sticky');
            }
        }
    }

    return (
        <>
            <div className="flex flex-col h-screen justify-between releative">
                <Navbar />
                {children}
                {/* Footer section */}
                <Footer />

                {/* Switcher section */}
                <Switcher />
            </div>
        </>
    );
}
