import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, ChevronDownIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { generalFetch } from '../api/generalFetcher';
import { useAuth, useAuthDispatch } from '../store/AuthContext';
import { useSetting } from '../store/SettingContext';

const Navbar = () => {
    const location = useLocation();
    const setting = useSetting();
    const auth = useAuth();
    const dispatch = useAuthDispatch();
    const { data: menuResponse, error, isLoading } = useSWR('/menu', generalFetch);

    const { data: chatsResponse } = useSWR('/chat', (url) => generalFetch(url, auth.token), {
        refreshInterval: 30000,
    });
    const isNewChat = chatsResponse?.agencies?.filter((chat) => chat.unread_chat !== 0).length;

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    const logoutHandler = () => {
        dispatch({
            type: 'logout',
        });
    };

    return (
        <>
            <Disclosure as="nav" className="navbar" id="navbar">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-2 md:px-6 lg:px-8">
                            <div className="relative flex py-1 px-2.5 items-center justify-between">
                                <div className="flex items-center md:hidden w-1/3 md:w-auto">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:bg-gray-200 hover:text-gray-800 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex justify-center w-1/3 md:w-auto">
                                    {setting.wideLogo && (
                                        <Link className="md:me-12" to="/">
                                            <div className="flex flex-shrink-0 items-center">
                                                <img className="h-12 w-auto" src={setting.wideLogo} alt="Your Company" />
                                            </div>
                                        </Link>
                                    )}
                                    <div className="hidden md:ml-6 md:flex md:items-center navbar-nav">
                                        <div className="flex space-x-4">
                                            {!error &&
                                                !isLoading &&
                                                menuResponse.menus.map((menu) => {
                                                    if (menu.is_active === 'yes') {
                                                        return (
                                                            <Fragment key={menu.id}>
                                                                {menu.type !== 'submenu' ? (
                                                                    <>
                                                                        {menu.type === 'external' ? (
                                                                            <a href={menu.path} target="_self" rel="noreferrer noopener" className="nav-item" activeclassname="active">
                                                                                <span className="nav-link">{menu.name}</span>
                                                                            </a>
                                                                        ) : (
                                                                            <NavLink to={menu.path} className="nav-item" activeclassname="active">
                                                                                <span className="nav-link">{menu.name}</span>
                                                                            </NavLink>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className="nav-item inline-flex items-center focus:outline-none">
                                                                                <span className="nav-link">{menu.name}</span>
                                                                                <ChevronDownIcon className="ml-1 h-3.5 w-3.5" />
                                                                            </Menu.Button>
                                                                        </div>

                                                                        <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                                                            <Menu.Items className="origin-top-center absolute left-1/2 transform -translate-x-1/2 mt-2 py-2.5 px-1 w-56 rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {menu.submenus.map((submenu) => (
                                                                                        <Menu.Item as={Fragment} key={submenu.id} className="focus:outline-none hover:bg-transparent focus:bg-transparent">
                                                                                            {({ active }) => (
                                                                                                <>
                                                                                                    {submenu.type === 'external' ? (
                                                                                                        <NavLink to={submenu.path} className={classNames(active ? 'bg-gray-100 text-emerald-600' : 'text-gray-700', 'block px-4 py-2 text-sm hover:bg-transparent hover:text-emerald-600 font-medium')}>
                                                                                                            {submenu.name}
                                                                                                        </NavLink>
                                                                                                    ) : (
                                                                                                        <NavLink to={submenu.path} className={classNames(active ? 'bg-gray-100 text-emerald-600' : 'text-gray-700', 'block px-4 py-2 text-sm hover:bg-transparent hover:text-emerald-600 font-medium')}>
                                                                                                            {submenu.name}
                                                                                                        </NavLink>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    ))}
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    }
                                                    return false;
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end space-x-2 w-1/3 md:w-auto">
                                    {!auth.isLoggedIn ? (
                                        <li className="inline ms-1">
                                            <Link to="/login" className="btn btn-sm btn-icon rounded-full bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white">
                                                <i className="uil uil-signin"></i>
                                            </Link>
                                        </li>
                                    ) : (
                                        <div className="flex justify-end items-center gap-x-3">
                                            {/* <Link to="/chat" className="hidden md:flex justify-center items-center bg-emerald-600 w-10 h-10 rounded-full group hover:bg-emerald-700 relative">
                                                <span className="sr-only">Chat</span>
                                                <div className="absolute inline-flex items-center justify-center w-3 h-3 text-xs font-medium text-white bg-red-500 shadow-lg rounded-full top-0 end-0"></div>
                                                <ChatBubbleLeftRightIcon className="w-6 h-6 text-gray-200 group-hover:text-white" aria-hidden="true" />
                                            </Link> */}
                                            <Link to="/chat" className="relative flex items-center justify-center h-9 w-9 bg-gray-100/50 border border-gray-100/75 rounded-full text-emerald-900/75 dark:border-emerald-50/50 dark:text-emerald-50 dark:bg-emerald-800/25">
                                                {isNewChat > 0 && (
                                                    <span className="absolute -right-0.5 -top-0.5 z-1 h-2 w-2 rounded-full bg-emerald-600 dark:bg-emerald-50">
                                                        <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-emerald-600/25 dark:bg-emerald-50/25 opacity-75" />
                                                    </span>
                                                )}
                                                <svg className="fill-current duration-300 ease-in-out" width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.9688 1.57495H7.03135C3.43135 1.57495 0.506348 4.41558 0.506348 7.90308C0.506348 11.3906 2.75635 13.8375 8.26885 16.3125C8.40947 16.3687 8.52197 16.3968 8.6626 16.3968C8.85947 16.3968 9.02822 16.3406 9.19697 16.2281C9.47822 16.0593 9.64697 15.75 9.64697 15.4125V14.2031H10.9688C14.5688 14.2031 17.522 11.3625 17.522 7.87495C17.522 4.38745 14.5688 1.57495 10.9688 1.57495ZM10.9688 12.9937H9.3376C8.80322 12.9937 8.35322 13.4437 8.35322 13.9781V15.0187C3.6001 12.825 1.74385 10.8 1.74385 7.9312C1.74385 5.14683 4.10635 2.8687 7.03135 2.8687H10.9688C13.8657 2.8687 16.2563 5.14683 16.2563 7.9312C16.2563 10.7156 13.8657 12.9937 10.9688 12.9937Z"
                                                        fill
                                                    />
                                                    <path d="M5.42812 7.28442C5.0625 7.28442 4.78125 7.56567 4.78125 7.9313C4.78125 8.29692 5.0625 8.57817 5.42812 8.57817C5.79375 8.57817 6.075 8.29692 6.075 7.9313C6.075 7.56567 5.79375 7.28442 5.42812 7.28442Z" fill />
                                                    <path d="M9.00015 7.28442C8.63452 7.28442 8.35327 7.56567 8.35327 7.9313C8.35327 8.29692 8.63452 8.57817 9.00015 8.57817C9.33765 8.57817 9.64702 8.29692 9.64702 7.9313C9.64702 7.56567 9.33765 7.28442 9.00015 7.28442Z" fill />
                                                    <path d="M12.5719 7.28442C12.2063 7.28442 11.925 7.56567 11.925 7.9313C11.925 8.29692 12.2063 8.57817 12.5719 8.57817C12.9375 8.57817 13.2188 8.29692 13.2188 7.9313C13.2188 7.56567 12.9094 7.28442 12.5719 7.28442Z" fill />
                                                </svg>
                                            </Link>
                                            <Menu as="div" className="relative">
                                                <div>
                                                    <Menu.Button className="relative flex items-center focus:outline-none text-right">
                                                        <span className="sr-only">User menu</span>
                                                        <div className="mx-4 hidden md:block">
                                                            <span className="text-slate-950 dark:text-white text-sm my-auto block capitalize">{auth.user.name}</span>
                                                            <span className="text-slate-600 dark:text-slate-300 text-xs my-auto block capitalize">{auth.user.role}</span>
                                                        </div>
                                                        <img
                                                            className="h-12 w-12 rounded-full"
                                                            src={auth.user.profile_picture_url || '/default-avatar.png'}
                                                            alt="profile"
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = '/no-image.png';
                                                            }}
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items className="absolute overflow-hidden right-0 z-10 mt-2 w-48 origin-top-right rounded-xl bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link to="/profile" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                                                    Profil
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link to="/booking" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                                                    Booking
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link to="/complaint" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                                                    Pengaduan
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button onClick={logoutHandler} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm')}>
                                                                    Sign out
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {!error &&
                                    !isLoading &&
                                    menuResponse.menus.map((menu) => {
                                        if (menu.is_active === 'yes') {
                                            return (
                                                <Fragment key={menu.id}>
                                                    {menu.type !== 'submenu' ? (
                                                        <>
                                                            {menu.type === 'external' ? (
                                                                <Disclosure.Button as="a" href={menu.path} target="_self" rel="noreferrer" className={classNames(location.pathname === menu.path ? 'text-[#059669]' : 'text-dark dark:text-white', 'block rounded-md px-3 py-2 text-base font-medium nav-item')} activeclassname="active" aria-current={location.pathname === menu.path ? 'page' : undefined}>
                                                                    <span className="nav-link">{menu.name}</span>
                                                                </Disclosure.Button>
                                                            ) : (
                                                                <Disclosure.Button as={NavLink} to={menu.path} className={classNames(location.pathname === menu.path ? 'text-[#059669]' : 'text-dark dark:text-white', 'block rounded-md px-3 py-2 text-base font-medium nav-item')} activeclassname="active" aria-current={location.pathname === menu.path ? 'page' : undefined}>
                                                                    <span className="nav-link">{menu.name}</span>
                                                                </Disclosure.Button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <Disclosure>
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button className="flex justify-between w-full px-3 py-2 text-base font-medium hover:bg-transparent focus:outline-none">
                                                                        <span>{menu.name}</span>
                                                                        <ChevronRightIcon className={classNames(open ? 'transform rotate-90' : '', 'w-5 h-5')} />
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel className="space-y-1">
                                                                        {menu.submenus.map((submenu) => (
                                                                            <Fragment key={submenu.id}>
                                                                                {submenu.type === 'external' ? (
                                                                                    <Disclosure.Button as="a" href={submenu.path} target="_self" rel="noreferrer" className={classNames(false ? 'text-[#059669]' : 'text-dark dark:text-white', 'flex items-center rounded-md px-3 py-2 text-base font-medium nav-item hover:text-emerald-600')} activeclassname="active">
                                                                                        <span className="ml-5">{submenu.name}</span>
                                                                                    </Disclosure.Button>
                                                                                ) : (
                                                                                    <Disclosure.Button as={NavLink} to={submenu.path} className={classNames(false ? 'text-[#059669]' : 'text-dark dark:text-white', 'flex items-center rounded-md px-3 py-2 text-base font-medium nav-item hover:text-emerald-600')} activeclassname="active">
                                                                                        <span className="ml-5">{submenu.name}</span>
                                                                                    </Disclosure.Button>
                                                                                )}
                                                                            </Fragment>
                                                                        ))}
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                    )}
                                                </Fragment>
                                            );
                                        }
                                        return false;
                                    })}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    );
};

export default Navbar;
