import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import useSWR from 'swr';
import { getMessaging, getToken } from 'firebase/messaging';
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';
import './assets/scss/icons.scss';
import './assets/css/ckeditor.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import app from './utility/firebase';
import { useAuth, useAuthDispatch } from './store/AuthContext';
import { useSettingDispatch } from './store/SettingContext';
import { settingFetch } from './api/generalFetcher';
import AllRoutes from './routes';

import Loader from './component/Loader';

export default function App() {
    const auth = useAuth();
    const authDispatch = useAuthDispatch();
    const dispatch = useSettingDispatch();
    const messaging = getMessaging(app);
    const { data: settingResponse, error } = useSWR('/setting', settingFetch);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.documentElement.setAttribute('dir', 'ltr');
        handleRouteChange();
    }, []);

    useEffect(() => {
        settingResponse &&
            !error &&
            dispatch({
                type: 'update',
                setting: settingResponse.setting,
            });
    }, [settingResponse, dispatch, error]);

    // Route change method
    const handleRouteChange = () => {
        setLoading(false);
    };

    const requestPermission = () => {
        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            }
        });
    };

    useEffect(() => {
        getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        })
            .then((currentToken) => {
                if (currentToken) {
                    console.log(currentToken);
                    authDispatch({
                        type: 'storeFcmToken',
                        fcmToken: currentToken,
                    });
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                    requestPermission();
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    }, [messaging, authDispatch, auth.user.id]);

    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <>
                    <AllRoutes />
                    <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} draggable pauseOnHover theme="colored" />
                </>
            )}
        </>
    );
}
