import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../component/Loader.js";

const AgencyIndex = React.lazy(() => import("../../pages/agency/index.js"));
const Show = React.lazy(() => import("../../pages/agency/Show.js"));
const NotFound = React.lazy(() => import("../../pages/NotFound.js"));

const AgencyRoutes = () => {
	return (
		<Routes>
			<Route
				index
				element={
					<Suspense fallback={<Loader />}>
						<AgencyIndex />
					</Suspense>
				}
			/>
			<Route
				path="/:agencyId/*"
				element={
					<Suspense fallback={<Loader />}>
						<Show />
					</Suspense>
				}
			/>
			<Route
				path="*"
				element={
					<Suspense fallback={<Loader />}>
						<NotFound />
					</Suspense>
				}
			/>
		</Routes>
	);
};

export default AgencyRoutes;
