export default function Loader() {
    return (
        <>
            <div id="preloader">
                <div id="status">
                    <div className="logo">
                        <img src="/mpp-logo-3.svg" className="d-block mx-auto animate-pulse" alt="" style={{ height: '128px' }} />
                    </div>
                    <div className="justify-content-center">
                        <div className="text-center">
                            <h4 className="mb-0 mt-2 text-lg font-semibold">Mal Pelayanan Publik Garut</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
