import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../component/Loader.js";
import ChatIndex from "../../pages/chat/index.js";

const Show = React.lazy(() => import("../../pages/chat/Show.js"));
const NotFound = React.lazy(() => import("../../pages/NotFound.js"));

const ChatRoutes = () => {
	return (
		<Routes>
			<Route
				index
				element={
					<Suspense fallback={<Loader />}>
						<ChatIndex />
					</Suspense>
				}
			/>
			<Route
				path="/:agencyId/*"
				element={
					<Suspense fallback={<Loader />}>
						<Show />
					</Suspense>
				}
			/>
			<Route
				path="*"
				element={
					<Suspense fallback={<Loader />}>
						<NotFound />
					</Suspense>
				}
			/>
		</Routes>
	);
};

export default ChatRoutes;
