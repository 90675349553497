import { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { Dialog, Transition } from "@headlessui/react";
import { generalFetch } from "../../api/generalFetcher";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const NewChatModal = ({ open, setOpen }) => {
	const navigate = useNavigate();
	const agencyIdInputRef = useRef();

	const {
		data: response,
		error,
		isLoading,
	} = useSWR(open ? `/agency?limit=-1` : null, generalFetch);

	useEffect(() => {
		if (error) {
			toast.error("Gagal mendapatkan data instansi !");
		}
	}, [error]);

	const startChatHandler = () => {
		if (!agencyIdInputRef.current.value) {
			toast.error("Pilih instansi !");
		} else {
			navigate(`/chat/${agencyIdInputRef.current.value}`);
		}
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-slate-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="bg-white dark:bg-slate-900 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div className="sm:items-start">
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-base font-semibold leading-6 text-neutral-800 dark:text-neutral-50">
												Mulai Live Chat Baru
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-neutral-600 dark:text-neutral-200">
													Pilih instansi untuk memulai
												</p>
											</div>
											<div className="text-center mt-4">
												{!isLoading &&
													!error &&
													response && (
														<select
															className="form-input mt-3 w-full sm:text-sm sm:leading-6"
															ref={
																agencyIdInputRef
															}>
															{response.agencies.data.map(
																(agency) => (
																	<option
																		value={
																			agency.id
																		}
																		key={
																			agency.id
																		}>
																		{
																			agency.name
																		}
																	</option>
																)
															)}
														</select>
													)}
												{(isLoading ||
													isLoading ||
													error) && <Skeleton />}
											</div>
										</div>
									</div>
								</div>
								<div className="bg-white dark:bg-slate-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									{!isLoading && !error && (
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-emerald-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
											onClick={startChatHandler}>
											Mulai
										</button>
									)}
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={() => setOpen(false)}>
										Batal
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default NewChatModal;
